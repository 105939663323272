import React from 'react';
import { StWrapReservation, Ddnayo } from './styles';

function Reservation() {
  return (
    <StWrapReservation>
      <Ddnayo url="https://booking.ddnayo.com/?accommodationId=12473" scrolling="yes" id="reserveframe"></Ddnayo>
    </StWrapReservation>
  );
}

export default Reservation;
