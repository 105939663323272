import styled from 'styled-components';

const StBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0)),
    url('https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/outside/1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BannerText = styled.p`
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding-left: 20px;
  z-index: 2;
`;

const BannerTitle = styled.h2`
  font-size: 2.5rem;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  margin: 10px 0 0 20px; /* Spacing below S9Trail */
`;

const BannerSubtitle = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  margin: 5px 0 0 20px; /* Spacing below title */
`;

export { StBanner, BannerText, BannerTitle, BannerSubtitle };
