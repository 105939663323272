import Iframe from 'react-iframe';
import styled from 'styled-components';

const StWrapReservation = styled.div`
  width: 100%;
  height: 2000px;
  background-color: #03174980;
`;

const Ddnayo = styled(Iframe)`
  width: 100%;
  height: 100%;
`;

export { StWrapReservation, Ddnayo };
