import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  flex-direction: row;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
`;

const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const LogoImage = styled.img`
  padding-left: 10px;
  height: 50px;
  width: auto;
`;

const LogoText = styled.p`
  padding-left: 10px;
  font-size: 20px;
`;

const NavBar = styled.nav`
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
`;

const NavBarRight = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
  height: 50px;
  display: flex;
`;

const NavBarItem = styled.li`
  height: 50px;
  margin-right: 20px;
  align-content: center;
`;

const StyledLink = styled(Link)`
  vertical-align: middle;
  text-decoration: none;
  color: black;
  font-size: 12px;
`;

const ReservationLink = styled(Link)`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: solid black;
  border-radius: 20px;
  height: 20px;
  vertical-align: middle;
  text-decoration: none;
  color: black;
  font-size: 12px;
`;

export { StHeader, LogoDiv, LogoImage, LogoText, NavBar, NavBarRight, NavBarItem, StyledLink, ReservationLink };
