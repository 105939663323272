import styled from 'styled-components';

const StWrapTravel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 50px;
  align-items: center;
`;

const TravelContents = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  justify-content: flex-start;
  gap: 20px;
  background-color: white;
`;

const TabSet = styled.ul`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  width: 220px;
  list-style-type: none;
  gap: 10px;
  flex-shrink: 0;
`;

const TabButton = styled.button<{ isActive?: boolean }>`
  height: 45px;
  background-color: ${({ isActive }) => (isActive ? '#e6f4e8' : '#ffffff')};
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  text-align: left;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid ${({ isActive }) => (isActive ? '#2c6e49' : '#e0e0e0')};
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: #f0f8f2;
    border-color: #2c6e49;
  }
`;

const WrapTravelInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex-grow: 1;
`;

const TravelInfo = styled.div`
  display: flex;
  padding-right: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
`;

const WrapTravelInfoText = styled.div`
  flex-grow: 1;
`;

const TravelTitle = styled.h2`
  font-size: 24px;
  color: #2c6e49;
  margin-bottom: 12px;
  font-weight: bold;
  display: inline-block;
`;

const TravelDistance = styled.span`
  font-size: 14px;
  color: #000;
  margin-left: 20px;
  display: inline-block;
`;

const WrapMainImage = styled.div`
  width: 350px;
  height: auto;
  flex-shrink: 0;
`;

const TravelMainImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  line-height: 1.5;
  width: 100%;
  overflow-wrap: break-word;
`;

const Subtext = styled.p`
  font-size: 16px;
  color: #555;
  margin: 0 0 15px 0;
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 15px 0;
  width: 100%;
`;

export {
  StWrapTravel,
  TravelContents,
  TabSet,
  TabButton,
  WrapTravelInfo,
  TravelInfo,
  WrapTravelInfoText,
  TravelTitle,
  TravelDistance,
  WrapMainImage,
  TravelMainImage,
  SectionWrapper,
  Subtext,
  StyledHr
};
