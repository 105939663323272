import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Reservation from '../pages/Reservation';
import Rooms from '../pages/Rooms';
import Facilities from '../pages/Facilities';
import Travel from '../pages/Travel';
import Footer from '../components/footer';
import Header from '../components/header/Header';
import { GlobalStyle, GlobalFonts } from '../styles/styles';

const Router = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <GlobalFonts />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="/reservation" element={<Reservation />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
export default Router;
