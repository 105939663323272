import React, { useState } from 'react';
import {
  StWrapTravel,
  TravelContents,
  TabSet,
  TabButton,
  WrapTravelInfo,
  TravelInfo,
  WrapTravelInfoText,
  TravelTitle,
  TravelDistance,
  WrapMainImage,
  TravelMainImage,
  SectionWrapper,
  Subtext,
  StyledHr
} from './styles';
import Banner from '../../components/banner';
import { travels } from '../../data/travelInfo';

function Travel() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    if (activeTab !== index) {
      setActiveTab(index);
    }
  };

  const travel = travels[activeTab];

  return (
    <StWrapTravel>
      <Banner title="Travel" text="Follow your dreams" />
      <TravelContents>
        <TabSet>
          {travels.map((travel, index) => (
            <TabButton key={index} isActive={activeTab === index} onClick={() => handleTabClick(index)}>
              {travel.name}
            </TabButton>
          ))}
        </TabSet>

        <WrapTravelInfo>
          <TravelInfo>
            <WrapTravelInfoText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TravelTitle>{travel.name}</TravelTitle>
                {travel.distance && <TravelDistance>{travel.distance}</TravelDistance>}
              </div>
              <StyledHr />

              <SectionWrapper>
                {travel.description.map((paragraph, index) => (
                  <Subtext key={index}>{paragraph}</Subtext>
                ))}
              </SectionWrapper>
            </WrapTravelInfoText>

            <WrapMainImage>
              <TravelMainImage src={travel.imageUrl} alt={`${travel.name} Image`} />
            </WrapMainImage>
          </TravelInfo>
        </WrapTravelInfo>
      </TravelContents>
    </StWrapTravel>
  );
}

export default Travel;
