interface RoomInfo {
  roomName: string;
  personnel: string;
  category: string;
  amenity: string[];
  significant: Array<string> | null;
  imageUrl: Array<string>;
}
const baseUrl = 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/rooms/';
const rooms: RoomInfo[] = [
  {
    roomName: '본부동 1층',
    personnel: '10/15',
    category: '방2개/거실/욕실2개/온돌',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: ['객실안 개별바베큐', '겨울 - 바베큐장 이용 시 화목난로 이용 가능(고구마 구워 드실 수 있습니다.)'],
    imageUrl: [baseUrl + 'main_101/1.jpg', baseUrl + 'main_101/2.jpg', baseUrl + 'main_101/3.jpg']
  },
  {
    roomName: '본부동 2층',
    personnel: '7/10',
    category: '방1개/거실/욕실1개/온돌',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: ['객실안 개별바베큐', '겨울 - 바베큐장 이용 시 화목난로 이용 가능(고구마 구워 드실 수 있습니다.)'],
    imageUrl: [baseUrl + 'main_201/1.jpg', baseUrl + 'main_201/2.jpg', baseUrl + 'main_201/3.jpg']
  },
  {
    roomName: '황토동 1층',
    personnel: '7/10',
    category: '방1개/큰 거실/욕실1개/온돌',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: ['객실안 개별바베큐', '겨울 - 바베큐장 이용 시 화목난로 이용 가능(고구마 구워 드실 수 있습니다.)'],
    imageUrl: [baseUrl + 'brown_101/1.jpg', baseUrl + 'brown_101/2.jpg', baseUrl + 'brown_101/3.jpg']
  },
  {
    roomName: '연두동(스파)',
    personnel: '2/4',
    category: '월풀',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: null,
    imageUrl: [baseUrl + 'green_201/1.jpg', baseUrl + 'green_201/2.jpg', baseUrl + 'green_201/3.jpg']
  },
  {
    roomName: '파랑동(스파)',
    personnel: '2/4',
    category: '월풀',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: ['202호: 월풀없음'],
    imageUrl: [baseUrl + 'blue_101/1.jpg', baseUrl + 'blue_101/2.jpg', baseUrl + 'blue_101/3.jpg']
  },
  {
    roomName: '노랑동 101호',
    personnel: '4/8',
    category: '복층, 월풀, 방2, 욕실2',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: null,
    imageUrl: [baseUrl + 'yellow_a/1.jpg', baseUrl + 'yellow_a/2.jpg', baseUrl + 'yellow_a/3.jpg']
  },
  {
    roomName: '노랑동 102호',
    personnel: '4/6',
    category: '복층, 월풀, 방2, 욕실2',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: null,
    imageUrl: [baseUrl + 'yellow_y102/1.jpg', baseUrl + 'yellow_y102/2.jpg', baseUrl + 'yellow_y102/3.jpg']
  },
  {
    roomName: '노랑동 202호',
    personnel: '4/6',
    category: '복층, 월풀, 방2, 욕실2',
    amenity: [
      '객실단독wifi',
      '냉장고',
      '전기밥솥',
      '무선전기주전자',
      '전자렌지',
      '화장대',
      '에어컨',
      'TV',
      '헤어드라이',
      '취사시설',
      '식기구',
      '조리기구일체',
      '개별바베큐',
      '식탁',
      '옷걸이',
      '샴푸&린스',
      '수건',
      '치약',
      '비누'
    ],
    significant: null,
    imageUrl: [baseUrl + 'yellow_y202/1.jpg', baseUrl + 'yellow_y202/2.jpg', baseUrl + 'yellow_y202/3.jpg']
  }
];

export { rooms };
