import React from 'react';
import {
  StFooter,
  WrapMain,
  WrapData,
  SmallText,
  Divider,
  WrapLinks,
  StyledLink,
  WrapSign,
  CopyrightText
} from './styles';

function Footer() {
  return (
    <StFooter>
      <WrapMain>
        <WrapData>
          <p>Logo</p>
          <br />
          <SmallText>S9 Pension</SmallText>
          <SmallText>경기 남양주시 수동면 축령산로 263번길34</SmallText>
          <br />
          <SmallText>010-1234-5678</SmallText>
          <br />
          <SmallText>대펴자: 오영식</SmallText>
          <SmallText>BANK_ 에스나인펜션(본부동) : 신한 110-443-915084 오영식</SmallText>
          <SmallText>스페셜하우스(연두동) : 농협 352-1357-9094-13 육근창</SmallText>
          <SmallText>스마일하우스(파랑동) : 우리은행 1002-258-796497 박창환</SmallText>
          <SmallText>스프렌도어하우스(노랑동) : 농협 352-1761-4544-93 이옥영</SmallText>
          <SmallText>석세스하우스(황토동) : 농협 546-01-007054 서경숙</SmallText>
        </WrapData>
        <WrapLinks>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/Rooms">Rooms</StyledLink>
          <StyledLink to="/Facilities">Facilities</StyledLink>
          <StyledLink to="/Travel">Travel</StyledLink>
          <StyledLink to="/Reservation">Reservation</StyledLink>
        </WrapLinks>
      </WrapMain>
      <Divider />
      <WrapSign>
        <CopyrightText>@2024 Yarden | All Rights Reserved</CopyrightText>
      </WrapSign>
    </StFooter>
  );
}

export default Footer;
