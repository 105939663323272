interface TravelInfo {
  index: number;
  name: string;
  description: string[];
  distance: string;
  imageUrl: string;
}

const travels: TravelInfo[] = [
  {
    index: 0,
    name: '축령상 자연휴양림',
    distance: '도보 5분거리',
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '자연을 벗삼아 휴양할 수 있는 공간, 축령산자연휴양림',
      '축령산은 남양주시와 가평군에 걸쳐있는 해발 886m의 산으로 숲이 울창하고 계곡이 아름다운 산입니다.',
      '조선왕조를 개국한 이성계가 고려말 사냥을 왔다가 짐승을 한 마리도 잡지 못하였는데 몰이꾼이 이 산은 신령스러운 산이라 산신제를 지내야 한다고하여 산 정상에 올라 제를 지낸 후 멧돼지를 잡았다는 전설이 있습니다.',
      '이때부터 고사를 올린 산이라 하여, 축령산이라고 불리게 되었다고 합니다.'
    ]
  },
  {
    index: 1,
    name: '몽골문화촌',
    distance: '', // Distance is blank as per the note
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '경기도 남양주시의 천마산 산자락에 자리잡고 있는 몽골문화촌은 1998년 남양주시와 몽골 울란바트라시가 체결한 우호협력 조약에 따라 만들어진 곳으로 몽골의 전통문화와 예술을 접할 수 있는 곳입니다.',
      '개관 당시 몽골의 전통집인 천막 게르(Ger) 7동, 마차형 게르 2동, 전시장 1동을 조성하였습니다.',
      '게르는 몽골 문화전시관과 전통찻집, 숙박시설 등으로 쓰이는데, 몽골 문화전시관에는 몽골에서 직접 가져온 의상, 장신구, 악기, 생활용품 200여 점이 전시되어 있으며 전통찻집에서는 몽골 전통차인 수태차, 인스니차를 팔며, 전통식당에서는 당나귀 고기로 만든 전골, 양고기찜, 찐만두 등 몽골 음식을 맛볼 수 있습니다.'
    ]
  },
  {
    index: 2,
    name: '물맑음수목원',
    distance: '자동차로 10~15분거리',
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '물맑음수목원은 산자수려하고 맑은 물이 굽이쳐 흘러 ‘물골안’이라 불리운 수동면에 아이들의 마음을 담아 자연친화적으로 조성하고 숲해설, 산림치유, 유아숲, 목공예 등 다양한 체험 프로그램을 운영하고 있습니다.'
    ]
  },
  {
    index: 3,
    name: '남양주종합촬영소',
    distance: '', // No distance provided
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '남양주종합촬영소는 남양주시 조안면에 위치하고 있으며, 영화촬영용 야외 세트와 규모별로 다양한 6개의 6실내 촬영스튜디오 그리고 녹음실, 각종 제작 장비 등을 갖춘 영화제작 시설입니다.',
      '영화 취화선과 공동경비구역 JSA를 촬영하기 위해 제작된 실물크기의 야외세트, 미니어처체험전시관, 영화문화관, 영상체험관, 영상원리체험관, 의상실, 소품실, 그리고 매 달 한편씩 한국영화를 무료로 상영하는 시네극장 등이 있습니다.'
    ]
  },
  {
    index: 4,
    name: '축령산/서리산',
    distance: '', // No distance provided
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '축령산과 서리산으로 이어지는 등산로는 누구나 쉽게 오를 수 있는 다양한 코스로서 짧은 시간에 정상 도전의 만족감을 느낄 수 있습니다.',
      '조용한 숲속에서 가족과 함께 하룻밤의 좋은 정감을 느낄 기회를 가질 수 있으며, 봄에는 서리산 정상의 철쭉꽃, 여름에는 바위와 숲이 조화된 시원한 계곡, 가을의 아름다운 단풍, 겨울의 설경 등 뚜렷한 계절감각을 느낄 수 있습니다.',
      '축령산의 울창한 수림과 계곡을 이용하여 자연휴양림을 조성했습니다.',
      '삼림욕장, 휴게소, 체육시설, 놀이시설, 야영장 등 편의시설이 두루 갖추어진 곳입니다.'
    ]
  },
  {
    index: 5,
    name: '아침고요수목원',
    distance: '', // No distance provided
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '20개의 주제를 가진 정원은 아름답게 가꾸어진 잔디밭과 화단, 자연스러운 산책로로 연결되어 있습니다.',
      '특히, 아름다운 대한민국의 금수강산을 실제 한반도지형 모양으로 조성하여 최고 절정의 꽃으로 표현한 하경정원(Sunken Garden)은 관광객들의 관심을 가장 많이 끄는 곳입니다.',
      '또한, 이곳에는 백두산 식물 300여종을 포함한 5,000여 종의 식물들을 만나볼 수 있으며, 영화 편지를 시작으로, 영화 조선명탐정, 중독, 드라마 구르미 그린 달빛, 웃어라동해야, 미남이시네요, 이죽일놈의사랑, 예능 무한도전 등의 촬영장소로 알려져 많은 관광객이 다녀가고 있습니다.'
    ]
  },
  {
    index: 6,
    name: '남양주유기농테마파크',
    distance: '', // No distance provided
    imageUrl: 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/Test.jpeg',
    description: [
      '<코코몽팜빌리지>',
      '어린이들에게 복잡하고 지루한 설명 대신 직접 체험하고 놀며 유기농을 배우는 기회를 제공합니다.',
      '아이들은 하루 동안 꼬마농부가 되어 자연스럽게 유기농을 몸으로 체험합니다.',
      '매표소 입구에서부터 얕은 언덕을 따라 헛간놀이터, 요리교실, 유기농교실, 전통체험장, 아로미텃밭, 트랙터놀이터, 동물농장 순으로 들어섰습니다.',
      '헛간놀이터와 유기농교실, 요리교실은 실내이며, 나머지 체험 공간은 야외입니다.',
      '각 시설을 돌아보는 순서는 없습니다. 아이들이 원하는 대로 체험하면 됩니다.'
    ]
  }
];

export { travels };
