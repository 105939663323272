import React, { useState } from 'react';
import {
  StRooms,
  RoomsLayout,
  RoomSet,
  RoomNum,
  WrapRoomInfo,
  RoomInfo,
  WrapRoomInfoText,
  TextAlign,
  Texts,
  Subtext,
  WrapMainImage,
  WrapRoomImages,
  RoomMainImage,
  SectionTitle,
  SectionWrapper,
  StyledHr,
  RoomImages
} from './styles';
import Banner from '../../components/banner';
import { rooms } from '../../data/roomInfo';

function Rooms() {
  const roomNum: string[] = [
    '본부동 1층',
    '본부동 2층',
    '황토동 1층',
    '연두동(스파)',
    '파랑동(스파)',
    '노랑동 101호',
    '노랑동 102호'
  ];

  const [selectedRoom, setSelectedRoom] = useState<string>(roomNum[0]);

  const handleRoomClick = (room: string) => {
    if (selectedRoom !== room) {
      setSelectedRoom(room);
    }
  };

  const filteredRoomInfo = rooms.filter((room) => room.roomName.includes(selectedRoom));

  return (
    <StRooms>
      <Banner title="Rooms" text="For your comfort" />
      <RoomsLayout>
        <RoomSet>
          {roomNum.map((num, index) => (
            <RoomNum key={index} isActive={selectedRoom === num} onClick={() => handleRoomClick(num)}>
              {num}
            </RoomNum>
          ))}
        </RoomSet>

        {selectedRoom &&
          filteredRoomInfo.map((roomInfo, index) => (
            <WrapRoomInfo key={index}>
              <RoomInfo>
                <WrapRoomInfoText>
                  <SectionTitle>{selectedRoom}</SectionTitle>
                  <StyledHr />
                  <SectionWrapper>
                    <Texts>인원 기준 / 최대:</Texts>
                    <Subtext>{roomInfo.personnel}</Subtext>
                  </SectionWrapper>
                  <SectionWrapper>
                    <Texts>객실 유형:</Texts>
                    <Subtext>{roomInfo.category}</Subtext>
                  </SectionWrapper>
                  <SectionWrapper>
                    <Texts>구비 시설:</Texts>
                    <TextAlign>
                      {roomInfo.amenity.map((amenities, index) => (
                        <li key={index}>{amenities}</li>
                      ))}
                    </TextAlign>
                  </SectionWrapper>
                  {roomInfo.significant && (
                    <>
                      <StyledHr />
                      <SectionWrapper>
                        <Texts>특이 사항:</Texts>
                        <Subtext>{roomInfo.significant}</Subtext>
                      </SectionWrapper>
                    </>
                  )}
                  <StyledHr />
                  <SectionWrapper>
                    <Texts>Gallery:</Texts>
                  </SectionWrapper>
                </WrapRoomInfoText>
                <WrapMainImage>
                  {roomInfo.imageUrl.length > 0 && <RoomMainImage src={roomInfo.imageUrl[0]} alt="Main room view" />}
                </WrapMainImage>
              </RoomInfo>
              <WrapRoomImages>
                {roomInfo.imageUrl.map((roomImage, index) => (
                  <RoomImages key={index} src={roomImage} alt={`Room view ${index + 1}`} />
                ))}
              </WrapRoomImages>
            </WrapRoomInfo>
          ))}
      </RoomsLayout>
    </StRooms>
  );
}

export default Rooms;
