import styled from 'styled-components';

const StWrapFacilities = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 50px;
`;

const FacilityContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const FacilitySummary = styled.div<{ isEven: boolean }>`
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: ${({ isEven }) => (isEven ? 'flex-start' : 'flex-end')};
`;

const FacilitiesImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
`;

const FacilitiesImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
`;

const SolidColorBlock = styled.div<{ isEven: boolean }>`
  position: absolute;
  top: 0;
  ${({ isEven }) => (isEven ? 'left: 0;' : 'right: 0;')}
  width: 25%;
  height: 100%;
  background: ${({ isEven }) => (isEven ? '#2E7D32' : '#1B5E20')};
  z-index: 2;
`;

const GradientOverlay = styled.div<{ isEven: boolean }>`
  position: absolute;
  top: 0;
  ${({ isEven }) => (isEven ? 'left: 25%;' : 'right: 25%;')}
  width: 200px;
  height: 100%;
  background: ${({ isEven }) =>
    isEven ? 'linear-gradient(to right, #2E7D32, transparent)' : 'linear-gradient(to left, #1B5E20, transparent)'};
  z-index: 2;
`;

// Title of the facility
const FacilitiesTitle = styled.h2<{ isEven: boolean }>`
  position: absolute;
  z-index: 3;
  bottom: 20px;
  ${({ isEven }) => (isEven ? 'left: 20px;' : 'right: 20px;')}
  color: #ffffff;
  font-size: 24px;
  margin: 0;
`;

const FacilityButton = styled.button<{ isEven: boolean }>`
  position: absolute;
  z-index: 3;
  bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #ffffff;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  ${({ isEven }) => (isEven ? 'right: 20px;' : 'left: 20px;')}
  padding: 5px 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
`;

const ArrowIcon = styled.span<{ isActive: boolean }>`
  display: inline-block;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  padding: 6px;
  transform: ${({ isActive }) => (isActive ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: transform 0.3s ease;
`;

const FacilityDetails = styled.div<{ isActive: boolean }>`
  background-color: #f9f9f9;
  height: ${({ isActive }) => (isActive ? '300px' : '0')};
  padding: ${({ isActive }) => (isActive ? '20px' : '0 20px')};
  overflow: hidden;
  transition: height 0.5s ease-in-out, padding 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: ${({ isActive }) => (isActive ? 'translateY(0)' : 'translateY(-100%)')};
  border-top: 1px solid #e0e0e0;
`;

const FacilitiesSeparator = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(to right, #2c6e49, transparent);
  margin-bottom: 20px;
`;

export {
  StWrapFacilities,
  FacilityContent,
  FacilitySummary,
  FacilitiesImageWrapper,
  SolidColorBlock,
  GradientOverlay,
  FacilitiesImage,
  FacilitiesTitle,
  FacilityButton,
  FacilityDetails,
  ArrowIcon,
  FacilitiesSeparator
};
