import {
  StHeader,
  LogoDiv,
  LogoImage,
  NavBar,
  NavBarRight,
  NavBarItem,
  StyledLink,
  ReservationLink,
  LogoText
} from './styles';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <StHeader>
      <LogoDiv>
        <Link to="/">
          <LogoImage src="S9Logo.jpeg" alt="Blog Logo" />
        </Link>
        <LogoText>S9Trail</LogoText>
      </LogoDiv>
      <NavBar>
        <NavBarRight>
          <NavBarItem>
            <StyledLink to="/Rooms">Rooms</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <StyledLink to="/Facilities">Facilities</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <StyledLink to="/Travel">Travel</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <ReservationLink to="/Reservation">Reservation</ReservationLink>
          </NavBarItem>
        </NavBarRight>
      </NavBar>
    </StHeader>
  );
}

export default Header;
