import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  FacilityButton,
  FacilityContent,
  FacilitiesTitle,
  FacilitiesImageWrapper,
  FacilitiesImage,
  SolidColorBlock,
  FacilityDetails,
  FacilitySummary,
  GradientOverlay,
  StWrapFacilities,
  FacilitiesSeparator,
  ArrowIcon
} from './styles';
import { FacilitiesData } from '../../data/facilitiesInfo';
import Banner from '../../components/banner';

class FacilityParams {
  constructor(
    public data: { index: number; title: string; description: string; expanded: boolean },
    public setActiveTab: Dispatch<SetStateAction<number>>,
    public activeTab: number,
    public placeImg: string
  ) {}
}

function Facilities() {
  const [activeTab, setActiveTab] = useState(-1);
  return (
    <StWrapFacilities>
      <Banner title="Facilities" text="Just do it" />
      <FacilitiesSeparator />
      {FacilitiesData.map((facilities, index) => (
        <Facility
          key={facilities.index}
          data={facilities}
          index={index}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          placeImg={facilities.placeImg}
        />
      ))}
    </StWrapFacilities>
  );
}

function Facility({ data, index, setActiveTab, activeTab, placeImg }: FacilityParams & { index: number }) {
  const isActive = data.index === activeTab;
  const isEven = index % 2 === 0;

  return (
    <FacilityContent>
      <FacilitySummary isEven={isEven}>
        <FacilitiesImageWrapper>
          <FacilitiesImage src={placeImg} alt={data.title} />
          <SolidColorBlock isEven={isEven} />
          <GradientOverlay isEven={isEven} />
        </FacilitiesImageWrapper>

        <FacilitiesTitle isEven={isEven}>{data.title}</FacilitiesTitle>
        <FacilityButton isEven={isEven} onClick={() => setActiveTab(isActive ? -1 : data.index)}>
          <ArrowIcon isActive={isActive} />
        </FacilityButton>
      </FacilitySummary>

      <FacilityDetails isActive={isActive}>
        <p>{data.description}</p>
      </FacilityDetails>
    </FacilityContent>
  );
}

export default Facilities;
