import styled from 'styled-components';

const StWrapHome = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HomeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 400px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0)),
    url('https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/outside/1.jpg');
  background-size: cover;
  background-position: center;
`;

const BannerText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 20px;
  color: #ffffff;
  font-size: 32px;
  z-index: 2;

  h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin: 0;
  }
`;

const BannerButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`;

const ReserveBtn = styled.button`
  padding: 10px 20px;
  background-color: #2e7d32;
  border: 2px solid #ffffff;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1b5e20;
  }
`;

const Divider = styled.hr`
  width: 90%;
  margin: 40px auto;
  border: none;
  border-top: 1px solid #e0e0e0;
`;

const CarouselHeader = styled.h1`
  font-size: 26px;
  margin: 20px 0;
  padding: 10px;
  text-align: center;
  color: #2e7d32;
  border-radius: 6px;
  background-color: #f0f4f8;
`;

const CarouselSection = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const ArrowButton = styled.button`
  background-color: #2e7d32;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.15);
    background-color: #1b5e20;
  }
`;

const CarouselWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  transition: transform 0.5s ease;
`;

const CarouselCard = styled.div`
  flex: 0 0 150px;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
`;

const CardTitle = styled.div`
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  background-color: #f0f4f8;
`;

const WrapHomeMap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px;
  background-color: #f1f8e9;
`;

const WrapLocationText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
`;

const LocationText = styled.h2`
  font-size: 32px;
  color: #2e7d32;
  margin: 0;
`;

const SubText = styled.p`
  font-size: 18px;
  color: #1b5e20;
  margin-top: 10px;
`;

const HomeMap = styled.div`
  flex-grow: 1;
  max-width: 600px;
  max-height: 400px;
  overflow: hidden;
`;

export {
  StWrapHome,
  HomeBanner,
  BannerText,
  BannerButtonContainer,
  ReserveBtn,
  CarouselSection,
  CarouselWrapper,
  CarouselCard,
  CardImage,
  CardTitle,
  ArrowButton,
  Divider,
  CarouselHeader,
  WrapHomeMap,
  WrapLocationText,
  LocationText,
  SubText,
  HomeMap
};
