import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  background-color: #d0d0d0;
`;

const WrapMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  height: 200px;
`;

const WrapData = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  font-size: 10px;
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  margin-left: 40px;
  margin-right: 40px;
  border-top: 1px solid;
`;

const WrapLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;
`;

const StyledLink = styled(Link)`
  vertical-align: middle;
  text-decoration: none;
  color: black;
  font-size: 12px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 12px;
`;

const WrapSign = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  height: 39px;
`;

const CopyrightText = styled.p`
  font-size: 8px;
`;

export { StFooter, WrapMain, WrapData, SmallText, Divider, WrapLinks, StyledLink, WrapSign, CopyrightText };
