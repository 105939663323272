import React, { useState } from 'react';
import {
  StWrapHome,
  HomeBanner,
  BannerText,
  BannerButtonContainer,
  ReserveBtn,
  CarouselSection,
  CarouselWrapper,
  CarouselCard,
  CardImage,
  CardTitle,
  ArrowButton,
  WrapHomeMap,
  WrapLocationText,
  LocationText,
  SubText,
  HomeMap,
  Divider,
  CarouselHeader
} from './styles';
import KakaoMap from './KakaoMap';
import { useNavigate } from 'react-router-dom';
import { FacilitiesData } from '../../data/facilitiesInfo';
import { rooms } from '../../data/roomInfo';

function Home() {
  const navigate = useNavigate();
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [currentFacilityIndex, setCurrentFacilityIndex] = useState(0);

  const handleReserveBtn = () => {
    navigate(`/reservation`);
  };

  type Direction = 'left' | 'right';

  const handleRoomNavigation = (direction: Direction) => {
    setCurrentRoomIndex((prevIndex) =>
      direction === 'left' ? (prevIndex - 1 + rooms.length) % rooms.length : (prevIndex + 1) % rooms.length
    );
  };

  const handleFacilityNavigation = (direction: Direction) => {
    setCurrentFacilityIndex((prevIndex) =>
      direction === 'left'
        ? (prevIndex - 1 + FacilitiesData.length) % FacilitiesData.length
        : (prevIndex + 1) % FacilitiesData.length
    );
  };

  const getLoopedItems = <T,>(items: T[], currentIndex: number): T[] => {
    const visibleItems = [...items, ...items];
    const loopIndex = currentIndex % items.length;
    return visibleItems.slice(loopIndex, loopIndex + 4);
  };

  return (
    <StWrapHome>
      <HomeBanner>
        <BannerText>
          <h1>S9 펜션</h1>
          <p>남양주 수동계곡과 축령산 바로 아래 위치한 에스나인 펜션입니다.</p>
        </BannerText>
        <BannerButtonContainer>
          <ReserveBtn onClick={handleReserveBtn}>예약하기</ReserveBtn>
        </BannerButtonContainer>
      </HomeBanner>

      <Divider />

      <CarouselSection>
        <CarouselHeader>Available Rooms</CarouselHeader>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ArrowButton onClick={() => handleRoomNavigation('left')}>&lt;</ArrowButton>
          <CarouselWrapper>
            {getLoopedItems(rooms, currentRoomIndex).map((room, index) => (
              <CarouselCard key={index}>
                <CardImage src={room.imageUrl[0]} alt={room.roomName} />
                <CardTitle>{room.roomName}</CardTitle>
              </CarouselCard>
            ))}
          </CarouselWrapper>
          <ArrowButton onClick={() => handleRoomNavigation('right')}>&gt;</ArrowButton>
        </div>
      </CarouselSection>

      <CarouselSection>
        <CarouselHeader>Available Facilities</CarouselHeader>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ArrowButton onClick={() => handleFacilityNavigation('left')}>&lt;</ArrowButton>
          <CarouselWrapper>
            {getLoopedItems(FacilitiesData, currentFacilityIndex).map((facility, index) => (
              <CarouselCard key={index}>
                <CardImage src={facility.placeImg} alt={facility.title} />
                <CardTitle>{facility.title}</CardTitle>
              </CarouselCard>
            ))}
          </CarouselWrapper>
          <ArrowButton onClick={() => handleFacilityNavigation('right')}>&gt;</ArrowButton>
        </div>
      </CarouselSection>

      <Divider />

      <WrapHomeMap>
        <WrapLocationText>
          <LocationText>Location</LocationText>
          <SubText>찾아오시는 길</SubText>
        </WrapLocationText>
        <HomeMap>
          <KakaoMap />
        </HomeMap>
      </WrapHomeMap>
    </StWrapHome>
  );
}

export default Home;
