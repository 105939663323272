import { BannerText, StBanner, BannerTitle, BannerSubtitle } from './styles';

function Banner({ title, text }: { title?: string; text?: string }) {
  return (
    <StBanner>
      <div>
        <BannerText>S9Trail</BannerText>
        {title && <BannerTitle>{title}</BannerTitle>}
        {text && <BannerSubtitle>{text}</BannerSubtitle>}
      </div>
    </StBanner>
  );
}

export default Banner;
