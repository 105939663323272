import { Map, MapMarker, useKakaoLoader } from 'react-kakao-maps-sdk';
import styled from 'styled-components';
import React from 'react';
import s9 from '../../assets/에스나인.jpg';

function KakaoMap() {
  const [loading, error] = useKakaoLoader({
    appkey: process.env.REACT_APP_KAKAO_MAP_API_KEY || ''
  });
  return (
    <>
      <MapWrapper>
        <Map
          center={{
            // 지도의 중심좌표
            lat: 37.7529727712875,
            lng: 127.306920941092
          }}
          style={{
            // 지도의 크기
            width: '100%',
            height: '450px'
          }}
          level={3}
        >
          <MapMarker
            //시작 마커
            position={{
              lat: 37.7529727712875,
              lng: 127.306920941092
            }}
          >
            에스나인 펜션
          </MapMarker>
        </Map>
      </MapWrapper>
    </>
  );
}

export default KakaoMap;

const MapWrapper = styled.div`
  display: flex;
  width: 778px;
  height: 425px;
`;
