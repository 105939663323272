const baseUrl = 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/special/';
const specialPlace = [
  baseUrl + 'outdoors_bbq/1.jpg',
  baseUrl + 'swimming_pool/1.jpg',
  baseUrl + 'kids_playground/1.jpg',
  baseUrl + 'kids_playground/1.jpg'
];

const FacilitiesData: { index: number; title: string; description: string; expanded: boolean; placeImg: string }[] = [
  {
    index: 0,
    title: 'Barbeque',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'outdoors_bbq/1.jpg'
  },
  {
    index: 1,
    title: 'Swimming Pool',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'swimming_pool/1.jpg'
  },
  {
    index: 2,
    title: 'Table Tennis',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'table_tennis/1.jpg'
  },
  {
    index: 3,
    title: 'Karaoke',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'karaoke/1.jpg'
  },
  {
    index: 4,
    title: 'Foot Netball',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'footnetball/1.jpg'
  },
  {
    index: 5,
    title: 'Futsal',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'futsal/1.jpg'
  },
  {
    index: 6,
    title: 'Kids PlayGround',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'kids_playground/1.jpg'
  },
  {
    index: 7,
    title: 'Pool Table',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'pool_table/1.jpg'
  },
  {
    index: 8,
    title: 'Private BBQ',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'private_bbq/green_201_outdoors/1.jpg'
  },
  {
    index: 9,
    title: 'Private Spa',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'private_spa/1.jpg'
  },
  {
    index: 10,
    title: 'Chicken Soup',
    description: 'This place is amazing!',
    expanded: false,
    placeImg: baseUrl + 'chicken_soup/1.jpg'
  }
];

export { FacilitiesData };
