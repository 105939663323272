import styled from 'styled-components';

const StRooms = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 50px;
  align-items: center;
`;

const RoomsLayout = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  justify-content: flex-start;
  background-color: white;
`;

const RoomSet = styled.ul`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  width: 220px;
  list-style-type: none;
  gap: 10px;
`;

const RoomNum = styled.button<{ isActive?: boolean }>`
  height: 45px;
  background-color: ${({ isActive }) => (isActive ? '#e6f4e8' : '#ffffff')};
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  text-align: left;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid ${({ isActive }) => (isActive ? '#2c6e49' : '#e0e0e0')};
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: #f0f8f2;
    border-color: #2c6e49;
  }
`;

const WrapRoomInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex-grow: 1;
`;

const RoomInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  position: relative;
`;

const WrapRoomInfoText = styled.div`
  padding-right: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
`;

const WrapMainImage = styled.div`
  width: 350px;
  height: auto;
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 1;
`;

const RoomMainImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const WrapRoomImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
`;

const RoomImages = styled.img`
  width: 250px;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #2c6e49;
  margin-bottom: 12px;
  font-weight: bold;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TextAlign = styled.ul<{ type?: string }>`
  margin-bottom: 10px;
  padding-left: 20px;
  list-style-type: ${({ type }) => type || 'circle'};
  font-size: 16px;
  color: #333;
`;

const Texts = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #2c6e49;
`;

const Subtext = styled.p`
  font-size: 16px;
  margin: 5px 0;
  line-height: 1.5;
  color: #555;
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 15px 0;
  width: 100%;
`;

export {
  StRooms,
  RoomsLayout,
  RoomSet,
  RoomNum,
  WrapRoomInfo,
  RoomInfo,
  WrapRoomInfoText,
  TextAlign,
  Texts,
  WrapMainImage,
  RoomMainImage,
  WrapRoomImages,
  RoomImages,
  SectionTitle,
  SectionWrapper,
  Subtext,
  StyledHr
};
